import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { emphasize, fade, darken } from '@material-ui/core/styles/colorManipulator'
import { withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import queryString from "query-string";

var qs = require('qs');

const useStyles = makeStyles(theme => ({
  button:{
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    color: 'white',
    fontSize: theme.typography.pxToRem(11),
    '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, .5),
      },
  },
}));

const CategoryPill = props => {
  let history = useHistory();
  const theme = withTheme((theme)=> theme);
  console.log(theme)
  const classes = useStyles();
  const {category} = props;

  const search = (e) => {
    e.preventDefault();
    const qst = queryString.stringify({ term: category, });

    console.log(qst)
    history.push(`/search/?${encodeURI(qst)}`);
  }


  return (
      <Button
        size="small"
        variant="outlined"
        onClick={search}
        className={classes.button}>
        <strong>{category}</strong>
    </Button>
  );
}

export default CategoryPill;

import React from 'react';
import { Map as LeaftletMap, Marker, Popup, TileLayer, Point } from 'react-leaflet';
import debounceRender from 'react-debounce-render';
import Leaflet from 'leaflet'

import Typography from '@material-ui/core/Typography';

                 //shadowUrl: require('./public/leaf-shadow.png'),
                 //shadowSize:   [ 50, 64 ], // size of the shadow
                 //shadowAnchor: [ 4, 62 ],  // the same for the shadow
const image = new Leaflet.Icon({
                 iconUrl: require('./public/blue-pin.png'),
                 iconSize:     [ 40, 40 ], // size of the icon
                 iconAnchor:  [ 20, 40 ], // point of the icon which will correspond to marker's location
                 popupAnchor:  [ 0, 45 ]// point from which the popup should open relative to the iconAnchor
             })

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Typography variant='body1' gutterBottom>
          <strong>
            Likely, No Results where found
          </strong>
        </Typography>
      );
    }
    const { data, className } = this.props;
    const houstonCenter = [29.76328, -95.36327];
    // const exampleBounds [[50.505, -29.09], [52.505, 29.09]]
    if (data.length === 0 ){
      return (<span>Need more results to render Map</span>)
    }
    const lats = data.map(d => d.locaton[0]).filter(d => !!d);
    const lons = data.map(d => d.locaton[1]).filter(d => !!d);
    const maxLat = Math.max(...lats);
    const minLat = Math.min(...lats);
    const maxLon = Math.max(...lons);
    const minLon = Math.min(...lons);
    const upperLeftBound = [minLat, maxLon];
    const upperRightBound = [maxLat, maxLon];
    const lowerLeftBound = [minLat, minLon];
    const lowerRightBound = [maxLat, minLon];
    const bounds = [upperLeftBound, upperRightBound, lowerLeftBound, lowerRightBound];
    const accessToken =
      'pk.eyJ1IjoiZHlsYW5iYXJ0aCIsImEiOiJjajB2ZWVoYzkwMDFpMndyczVnemlrMHNmIn0.1PyuXuRVF9WIcmlMdU_yXw';
    const id = 'mapbox/streets-v11';
    const mapProps = {};
    if (data.length === 1) {
      mapProps.zoom = 11;
      mapProps.center = [data[0].latitude, data[0].longitude];
    } else {
      mapProps.bounds = bounds;
    }
    return (
      <LeaftletMap className={className} {...mapProps} id="search-results-map">
        <TileLayer
          url={`https://api.tiles.mapbox.com/v4/${id}/{z}/{x}/{y}.png?access_token=${accessToken}`}
          attribution="Map data &copy; <a href=&quot;http://openstreetmap.org&quot;>OpenStreetMap</a> contributors, <a href=&quot;http://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery © <a href=&quot;http://mapbox.com&quot;>Mapbox</a>"
        />
        {data.map((d, i) => {
          const pos = [parseFloat(d.locaton[0]), parseFloat(d.locaton[1])];
          if (!pos.every(n => n)) {
            return null;
          }
          const brief =
            d.description && d.description.length > 100
              ? `${d.description.substring(0, 100).trim()}...`
              : d.description;
          console.log('map', pos)
          return (
            <Marker key={i} position={pos} >
              <Popup>
                <div>
                  <Typography variant='body1' gutterBottom>
                    <a href={`/r/detail/r/${d.id}`}>{d.name}</a>
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    {brief}
                  </Typography>
                </div>
              </Popup>
            </Marker>
          );
        })}
      </LeaftletMap>
    );
  }
}

export default debounceRender(Map, 200)

import React from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';

import {Clamp} from 'common/ReadMore';
import Category from 'common/Categories';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  shortTitles: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  }
}));

export default function SimpleCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  console.log(props)
  const {resource, className} = props;

  return (
    <Card className={className}>
      <CardContent>
        <Typography variant="h5" color="textSecondary" gutterBottom>
          <strong className={classes.shortTitles}>{resource['name']}</strong>
        </Typography>
        { resource['category']?.split(',')?.map( item =>
          (
            <Category category={item} />
          ))
        }
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          <br />
          <Clamp>
          {resource['description']}
          </Clamp>
        </Typography>
        <br />
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Contact Info:
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Phone: {resource['phone number']}
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Address: {resource['physical address']}
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Website: {resource['website']}
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Email: {resource['email']}
        </Typography>
        <br />
        <Typography className={classes.pos} color="textSecondary">
          Posted {moment(resource['creation date']).fromNow()}
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" size="small">Check Availability</Button>
      </CardActions>
    </Card>
  );
}

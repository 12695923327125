import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import clsx from 'clsx';
import debounce from 'debounce';

import { makeStyles } from '@material-ui/styles';
import { Paper, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import queryString from "query-string";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  space: {
    marginLeft: theme.spacing(1)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const debounceCall = debounce(func => func(), 500)

const SearchInput = props => {
  let history = useHistory();
  const query = queryString.parse(window.location.search);
  const [Term, setTerm] = useState(query?.term ? decodeURI(query.term) : "");
  const [ZipCode, setZipCode] = useState(query?.ZipCode ? decodeURI(query.ZipCode) : "");
  const classes = useStyles();

  const { className, style, ...rest } = props;

  useEffect(() => {
    history.listen((location, action) => {
      console.log("on route change");
      const query = queryString.parse(window.location.search);
      setTerm(query?.term ? decodeURI(query.term): "");
      setZipCode(query?.ZipCode ?  decodeURI(query.ZipCode) : "");
      console.log('location changed!');
    });
  }, []);


  const TermUpdate = (event) => {
    let value = event.target.value;
    setTerm(value)
    debounceCall(() => {
      const qst = queryString.stringify({
        term: value,
        ZipCode
      });
      history.push(`/search/?${encodeURI(qst)}`);
    });
  }

  const ZipCodeUpdate = (event) => {
    let value = event.target.value;
    setZipCode(value)
    debounceCall(() => {
      const qst = queryString.stringify({
        term: Term,
        ZipCode: value,
      });
      // PAGE goes BLANK
      history.push(`/search/?${encodeURI(qst)}`);
    });
  }


  return (
    <>
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        value={Term}
        onChange={TermUpdate}
        placeholder={`Search`}
      />
    </Paper>
    <span className={classes.space}/>
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        value={ZipCode}
        onChange={ZipCodeUpdate}
        placeholder={`ZipCode`}
      />
      <SearchIcon className={classes.icon} />
    </Paper>
    </>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;

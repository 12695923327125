import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import {dafault as light, darkTheme } from './theme';
import './assets/scss/index.scss';
import Routes from './Routes';

const browserHistory = createBrowserHistory();
const SearchContext = React.createContext()


const App = props => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>{
      if ( prefersDarkMode ){
        return darkTheme
      }
      return createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      })
    },
    [prefersDarkMode],
  );

  console.log(theme.palette.primary.main)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Router history={browserHistory}>
          <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;

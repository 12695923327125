import React, { Fragment, useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Map from 'components/Map/Map'


import {
  Loading as LoadingIndicator,
  Queries,
  Resources,
} from "components";


import debounce from 'debounce';

import queryString from "query-string";

const useStyles = makeStyles(theme => ({
  full: {
    width: '100%',
  },
  half: {
    width: '50%',
  },
  map: {
    paddingTop: theme.spacing(1),
    width: '100%',
    minWidth: '400px',
    top: '5rem',
    position: 'sticky !important',
    display: 'block',
  },
  firsttop: {
    paddingTop: theme.spacing(2),
  },
  spacing: {
    marginBottom: theme.spacing(3),
  },
}));



const debounceCall = debounce(func => func(), 200)

function App() {
  let history = useHistory();
  const query = queryString.parse(window.location.search);
  const [Loading, setLoading] = useState(true);
  const [Data, setData] = useState({ hits: [] });
  const [Term, setTerm] = useState(query?.term ? decodeURI(query.term) : "");
  const [ZipCode, setZipCode] = useState(query?.ZipCode ? decodeURI(query.ZipCode) : "");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });
  const classes = useStyles();


  useEffect(() => {
    history.listen((location, action) => {
      console.log("on route change");
      const query = queryString.parse(window.location.search);
      setTerm(query?.term ? decodeURI(query.term): "");
      setZipCode(query?.ZipCode ?  decodeURI(query.ZipCode) : "");
      console.log('location changed!');
    });
  }, []);

  useEffect(() => {
    async function fetchData(){
      const response = await fetch('/api/search', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          Term, ZipCode
        }) // body data type must match "Content-Type" header
      });
      // parses JSON response into native JavaScript objects
      response.json().then((value) => {
        setLoading(false)
        console.log(value);
        setData(value);
        // expected output: "Success!"
      });
    }
    setLoading(true)
    fetchData()
    console.log(Data)
  }, [Term, ZipCode]);

  if (Loading){
    return (
    <div>
      <LoadingIndicator />
    </div>
    )
  }

  return (
    <Grid
      className={classes.sizer}
      container={ true }
      spacing={2}
      justify="center"
      alignContent="left"
      direction="row"
    >

      <Grid item md={6} sm={12}>
      <div className={classes.firsttop} >
      {Data.resources ? Data.resources.map(item => (
        <div className={classes.spacing} key={item['response id']}>
          <Resources resource={item}/>
        </div>
      )): null}
      {/*
      {Data.queries ? Data.queries.map(item => (
        <div key={item['query id']}>
          <Queries className={classes.spacing} queries={item}/>
        </div>
      )): null}
      */}
      </div>
      </Grid>
      {Data.resources ? (
      <Grid className={isMobile? classes.full : null} item md={6} sm={12} xm={12}>
        <Map className={classes.map} data={Data.resources} />;
      </Grid>
      ): null}
    </Grid>
  );
}

export default App;

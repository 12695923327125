import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  clampLines: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
  }
})
);

export const Clamp = function(props){
  const inputRef = React.useRef();
  const [Ready, setReady] = React.useState(false);
  const [Hidden, setHidden] = React.useState(true);
  const [JustChildren, setJustChildren] = React.useState(props.jschild);
  const classes = useStyles();

  useEffect(() => {
    if (inputRef.current){
      setReady(true)
    } else {

    }
  }, [Hidden, inputRef]);

  const isEllipsisActive = (refrence) => {
    let e = refrence.current;
    if (!e){
      return false;
    }
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  }

  const {children} = props;

  if (JustChildren){
    return (<>{children}</>)
  }

  return (
    <>
    { Hidden ? (
      <>
      <Typography ref={inputRef} className={classes.clampLines}  variant='body1' align='left'>
        {children}
      </Typography>
      {isEllipsisActive(inputRef) && (
        <>
        <br/>
        <strong onClick={e => setHidden(!Hidden)}>[Click here to see more...]</strong>
        </>
      )}
      </>
      ):(
      <>
      <Typography ref={inputRef} variant='body1' align='left'>
        {children}
      </Typography>
      {/*
      {isEllipsisActive(inputRef) && (
        <>
        <strong onClick={e => setHidden(!Hidden)}>[Click here to see less...]</strong>
        <br/>
        </>
      )}
      */}
      </>
      )
    }
    </>
  );
}
